import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/Portfolio_Logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon4 from "../assets/img/navIcon4.svg";

export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/eoghan-%C3%B3-riain-5120a16b/"><img src={navIcon1} alt="LinkedIn"/></a>
                            <a href="https://github.com/eoriain/eoriain"><img src={navIcon4} alt="Github"/></a>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}