import {Col, Container, Tab, Row, Nav} from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png"
import projImg1 from "../assets/img/Thesis1.png";
import projImg2 from "../assets/img/Picture1.png";
import projImg3 from "../assets/img/WebXRProject.png";
import projImg4 from "../assets/img/eHealth.png";
import projImg5 from "../assets/img/uiuxRedesign.png";
import projImg6 from "../assets/img/pythonTurtle.png";
import projImg7 from "../assets/img/launch.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
    const projects_vr = [
        {
            title: "The Integration of Biosensors into an XR Experience",
            //description: "Description of project 1",
            imgUrl: projImg1,
        },
        {
            title: "VR Pacing Game for Long Covid",
            description: "The purpose of this experience is to teach sufferers of long covid to self-manage and plan pacing.",
            imgUrl: projImg2,
        },
        {
            title: "WebXR Project",
            //description: "Description of project 3",
            imgUrl: projImg3,
        },
    ];
    const projects_uxui = [
        {
            title: "eHealth: It's TIME",
            description: "A Website and App prototype developed using Figma for musculoskeletal pain support.",
            imgUrl: projImg4,
        },
        {
            title: "Redesign of a File Format Converter Website",
            description: "The purpose of this project was to identify a website and improve its user interface.",
            imgUrl: projImg5,
        },
        {
            title: "Using Python to Develop a Fractal Generator",
            description: "",
            imgUrl: projImg6,
        },
    ];
    const projects_mobile = [
        {
            title: "",
            description: "",
        },
        {
            title: "Solar System Mobile Application",
            description: "The purpose of this application is to allow the user to explore the 10 prominent elements of our solar system: The Sun, Mercury, Venus, Earth, Mars, Jupiter, Saturn, Uranus, Neptune and Pluto. Through the use of core data in the second version of the application, the user can add, delete and edit the data within the application. The user can select any of the 10 prominent elements to learn more about it and be presented with a 3D spherical, interactable object with the element's image mapped to it. The app also allow for the user to add their own elements and map their own images to the 3D sperical object.",
            imgUrl: projImg7,
        },
    ];
    return(
        <section className="project" id="project">
            <Container>
                <Row>
                    <Col>
                    <TrackVisibility>
                        {({isVisible}) =>
                            <div className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                <h2>
                                    Projects
                                </h2>
                                <p>
                                    Sample paragraph text to be inserted here.
                                </p>
                            </div>}
                    </TrackVisibility>
                    <Tab.Container id="projects-tabs" defaultActivityKey="first">
                        <Nav variant="pills" className="nav-pills mb-1 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Virtual Reality</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">UX/UI Design</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">Web Development</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">Mobile App Development</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth">Audio Engineering</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">Project Management</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <Row>
                                    {
                                        projects_vr.map((project, index) => {
                                            return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                        />
                                            )
                                        })
                                    }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">                                
                                <Row>
                                    {
                                        projects_uxui.map((project, index) => {
                                            return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                        />
                                            )
                                        })
                                    }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                text
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <Row>
                                    {
                                        projects_mobile.map((project, index) => {
                                            return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                        />
                                            )
                                        })
                                    }
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">Text 5</Tab.Pane>
                            <Tab.Pane eventKey="sixth">Text 6</Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}