import logo from './logo.svg';
import './App.css';
//import TRUNK from 'vanta/src/vanta.trunk';
import {useEffect} from "react";
import {NavBar} from './components/NavBar';
import {Banner} from './components/Banner';
import {Skills} from './components/Skills';
import {Projects} from './components/Projects';
import { Contact } from "./components/Contact";
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
// useEffect(() => {
//    TRUNK({
 //     el: "#trunk-bg",
 //     mouseControls: true,
 //     touchControls: true,
 //     gyroControls: false,
 //    minHeight: 200.00,
 //     minWidth: 200.00,
 //     scale: 1.00,
 //     scaleMobile: 1.00,
 //     color: 0x7c109d,
 //     backgroundColor: 0x160525,
 //     spacing: 10.00,
 //     chaos: 1.50
 //   })
 // }, [])

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
