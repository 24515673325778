import {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ArrowRightCircle} from "react-bootstrap-icons";
import TRUNK from 'vanta/src/vanta.trunk';
import headerImg from "../assets/img/me_distort.gif";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [text, setText] = useState('');
    const period = 2000;
    const [delta, setDelta] = useState(200 - Math.random()*100);
    const toRotate = ["Virtual Reality Developer", "Researcher", "3D Modeler", "Project Manager", "Problem Solver"];

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)
        return () => {clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
        
        setText(updatedText);

        if (isDeleting){
            setDelta(prevDelta => prevDelta/2)
        }

        if(!isDeleting && updatedText === fullText){
            setIsDeleting(true);
            setDelta(period);
        }
        else if(isDeleting && updatedText === '') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }
    
    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={8}>
                        <TrackVisibility>
                        {({isVisible}) =>
                            <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                <span className="tagline">
                                <h1>I'm a...</h1>
                                <h1><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Virtual Reality Developer", "Researcher", "UI/UX Designer" ]'><span className="wrap">{text}</span></span></h1>
                                </span>
                                <p>Self-motivated enthusiastic and reliable individual who enjoys working on their own or as part of a team. Confident person with excellent initiative and will undertake every task presented to them with attention to detail and efficiency. Currently working as a Researcher/Developer in the area of Virtual Reality for the School of Clinical Therapies and the School of Computer Science in University College Cork. Presently developing a VR experience for teaching pacing to patients with Long Covid as part of a multinational team for VR4Rehab. Previously developed a prototype VR experience which adapts to the user’s biosignals using a real-time feedback loop in concurrence with the biosensors. This application could then be used in training, education, gaming, etc. to create an individually customised user experience that optimises user engagement.</p>
                                <br/>
                            </div>}
                        </TrackVisibility>
                    </Col>
                    <Col xs={12} md={6} xl={4}>
                        <img src={headerImg} alt="Header Img"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}